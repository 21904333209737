import * as VueRouter from 'vue-router';
import * as vue from 'vue';
// import Antd from 'ant-design-vue';
import { createAuth0 } from '@auth0/auth0-vue';
import App from './App.vue';
import { API_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './constants';
import Emoji from './components/Emoji.vue';
import Dashboard from './pages/Dashboard.vue';
import Payment from './pages/Payment.vue';
import Risks from './pages/Risks.vue';
import 'ant-design-vue/dist/antd.css';
import '@ant-design/icons-vue';

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    { path: '/', redirect: '/dashboard' },
    { path: '/dashboard', component: Dashboard },
    { path: '/payment', component: Payment },
    { path: '/risks', component: Risks },
  ],
});

const app = vue.createApp(App);

app.component('Emoji', Emoji);

app.use(router);
// app.use(Antd);
app.use(createAuth0({
  domain: AUTH0_DOMAIN,
  client_id: AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: API_URL,
  scope: 'openid email profile',
}));

app.mount('#app');
