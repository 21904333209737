<template>
  <footer id="footer">
    © Copyright 2021 Virtual CISO, LLC.
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
    };
  },
});
</script>
