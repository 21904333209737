<template>
  <div>
    <!-- <router-link to="/payment">Subscribe</router-link> -->
    <!-- <a-row>
      <a-col span="8">
        <code class="w5">
          {{ token }}

        </code>
      </a-col>
    </a-row> -->
    <a-row
      :gutter="16"
      class="mv2">
      <a-col :span="6">
        <a-card>
          <a-statistic
            title="Cost of remediation"
            :value="11.28"
            :precision="0"
            suffix="K$"
            :value-style="{ color: '#cf1322' }"
          >
            <template #prefix>
              <arrow-up-outlined />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card>
          <a-statistic
            title="Lead time"
            :value="9"
            :precision="0"
            suffix="weeks"
            class="demo-class"
            :value-style="{ color: '#3f8600' }"
          >
            <template #prefix>
              <arrow-down-outlined />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card>
          <a-statistic
            title="Loss exceedance"
            :value="1.3"
            :precision="2"
            suffix="%"
            class="demo-class"
            :value-style="{ color: '#3f8600' }"
          >
            <template #prefix>
              <arrow-down-outlined />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card>
          <a-statistic
            title="ROI"
            :value="6.89"
            :precision="2"
            suffix="%"
            class="demo-class"
            :value-style="{ color: '#3f8600' }"
          >
            <template #prefix>
              <arrow-up-outlined />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>

    <a-card>
      <a-row>
        <a-col :span="6" />
        <a-col :span="12">
          <Line
            :chart-data="chartData"
            :chart-options="chartOptions" />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Line } from 'vue-chartjs';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons-vue';
import {
  Chart as ChartJS,
  Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale, LineElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
);

export default defineComponent({
  components: {
    Line,
    ArrowUpOutlined,
    ArrowDownOutlined,
  },
  async mounted() {
    this.token = await this.$auth0.getAccessTokenSilently();
  },
  data() {
    return {
      token: '',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chartData: {
        labels: ['$100', '$1K', '$10K', '$100K', '$1M', '$10M', '$100M'],
        datasets: [
          {
            label: 'Loss Tolerance',
            backgroundColor: '#777',
            data: [1, 0.7, 0.45, 0.25, 0.1, 0.03, 0.0001],
          },
          {
            label: 'Inherent loss',
            backgroundColor: '#f87979',
            data: [1, 0.65, 0.46, 0.3, 0.18, 0.10, 0.025],
          },
        ],
      },
    };
  },
});
</script>
