<template>
  <div class="h-100">
    <LandingPage v-if="!user" />
    <Layout v-if="user" />
  </div>
</template>
<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { defineComponent, ref } from 'vue';
import Layout from './components/Layout.vue';
import LandingPage from './pages/LandingPage.vue';

export default defineComponent({
  setup() {
    const { user } = useAuth0();
    return {
      selectedKeys: ref<string[]>(['1']),
      user,
    };
  },
  components: {
    Layout,
    LandingPage,
  },
  data() {
    return {
      loggedIn: false,
    };
  },
});
</script>

<style>
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>
