<template>
  <div>
    <a-button @click="fetchRisks">fetch</a-button>
    <a-table :dataSource="res" :columns="columns">
      <template #bodyCell="{ column, value }">
        <template v-if="column.dataIndex === 'Shape'">
          {{ JSON.stringify(value) }}
        </template>
        <template v-if="column.dataIndex === 'Likelihood'">
          {{ asPercentage(value) }}
        </template>
        <template v-if="column.dataIndex === 'Owner'">
          {{ JSON.stringify(value) }}
        </template>
        <template v-if="column.dataIndex === 'Issues'">
          {{ value.length }}
        </template>
        <template v-if="['Min loss', 'Max loss'].includes(column.dataIndex)">
          {{ new Intl.NumberFormat('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0,
            }).format(value)
          }}
        </template>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  components: {
  },
  async mounted() {
    this.token = await this.$auth0.getAccessTokenSilently();
  },
  data() {
    return {
      token: '',
      res: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'Name',
        },
        {
          title: 'Shape',
          dataIndex: 'Shape',
        },
        {
          title: 'Likelihood',
          dataIndex: 'Likelihood',
        },
        {
          title: 'Min loss',
          dataIndex: 'Min loss',
        },
        {
          title: 'Max loss',
          dataIndex: 'Max loss',
        },
        {
          title: 'Owner',
          dataIndex: 'Owner',
        },
        {
          title: 'Issues',
          dataIndex: 'Issues',
        },
        {
          title: 'Strategy',
          dataIndex: 'Strategy',
        },
      ],
    };
  },
  methods: {
    async fetchRisks () {
      const res = await fetch('http://localhost:8181/risks').then(res => res.json())
      this.res = res.map(r => r.fields);
    },
    asPercentage (value) {
      return `${value * 100}%` 
    }
  }
});
</script>
