<template>
  <a-layout class="layout min-vh-100">
    <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
      <Navbar />
    </a-layout-header>
    <a-layout>
      <a-layout-sider
        width="400"
        style="background: #fff"
        :style="{ padding: '0 50px', marginTop: '1rem' }"
      >
        <a-row class="mt5">
          <a-col>
            <a-typography-title>Onboarding checklist</a-typography-title>
            <a-typography-title :level="3">Getting started</a-typography-title>
            <a-progress
              :percent="50"
              size="small" />
            <a-list
              item-layout="horizontal"
              :data-source="data">
              <template #renderItem="{ item, index }">
                <a-list-item>
                  <a-list-item-meta
                    :description="item.description"
                  >
                    <template #avatar>
                      <a-avatar
                        size="large"
                        style="background-color: #fff; color: green">
                        <template #icon><check-circle-outlined /></template>
                      </a-avatar>
                    </template>
                    <template #title>
                      {{ index + 1 }}. {{ item.title }}
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </a-col>
        </a-row>
      </a-layout-sider>
      <a-layout-content :style="{ padding: '0 50px', marginTop: '64px' }">
        <router-view />
      </a-layout-content>
    </a-layout>
    <a-layout-footer :style="{ textAlign: 'center' }">
      <Footer />
    </a-layout-footer>
  </a-layout>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import Navbar from './Navbar.vue';
import Footer from './Footer.vue';

const data = [
  {
    title: 'First step',
    description: 'Description',
  },
  {
    title: 'Second step',
    description: 'Description',
  },
  {
    title: 'Third step',
    description: 'Description',
  },
];

export default defineComponent({
  setup() {
    return {
      data,
      selectedKeys: ref<string[]>(['1']),
    };
  },
  components: { Navbar, Footer, CheckCircleOutlined },
});
</script>

<style>
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>
