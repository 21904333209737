<template>
  <a-layout class="h-100">
    <a-layout-content class="h-100 pa1">
      <a-card>
        <a-row>
          <a-col
            :span="6"
            class="tc" />
          <a-col
            :span="12"
            class="tc">
            <h1>CSL Platform</h1>
            <p>
              <a-button
                @click="login"
                class="button">
                Login
              </a-button>
            </p>
          </a-col>
        </a-row>
      </a-card>
      <a-card>
        <a-row>
          <a-col
            :span="6"
            class="tc" />
          <a-col
            :span="12"
            class="tc">
            <p>
              <a
                href="https://sb1.cloudsecuritylabs.io/slack/install"
                target="_new">
                Install the Slack app
              </a>
            </p>
          </a-col>
        </a-row>
      </a-card>
    </a-layout-content>
  </a-layout>
</template>

<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { defineComponent } from 'vue';
import { API_URL } from '../constants';

export default defineComponent({
  setup() {
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();

    return {
      user,
      isAuthenticated,
      login: () => {
        loginWithRedirect();
      },
    };
  },
  data() {
    return {
    };
  },
});
</script>
