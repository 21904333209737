<template>
  <nav>
    <a-row>
      <a-col
        :span="3"
        class="white">
        CSL Platform
      </a-col>
      <a-col :span="17">
        <a-menu
          :selectedKeys="[]"
          theme="dark"
          mode="horizontal"
          :style="{ lineHeight: '64px' }"
        >
        <a-menu-item>
          <router-link key="/dashboard" tag="a-menu-item" to="/dashboard">
            Dashboard
          </router-link>
        </a-menu-item>
        <a-menu-item>
          <router-link key="/risks" tag="a-menu-item" to="/risks">
            Risks
          </router-link>
        </a-menu-item>
        </a-menu>
      </a-col>
      <a-col :span="4">
        <a-dropdown-button>
          {{ user.email }}
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item key="logout">
                Logout
              </a-menu-item>
            </a-menu>
          </template>
          <template #icon>
            <a-avatar
              size="small"
              :src="user.picture">
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </template>
        </a-dropdown-button>
      </a-col>
    </a-row>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { UserOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  setup() {
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    return {
      user,
      isAuthenticated,
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({ returnTo: window.location.origin });
      },
    };
  },
  components: {
    UserOutlined,
  },
  computed: {
    selectedRoutes () {
      console.log(this.$router.currentRoute.path)
      return [this.$router.currentRoute.path]
    }
  },
  methods: {
    handleMenuClick({ key }) {
      if (key === 'logout') {
        this.logout();
      }
    },
  },
  data() {
    return {
      selectedKeys: ref<string[]>(['1']),
    };
  },
});
</script>
