<template>
  <div>
    <form id="payment-form">
      <div id="payment-element" />
      <button id="submit">
        <div
          :if="loading"
          class="spinner"
          id="spinner" />
        <span id="button-text">Pay now</span>
      </button>
      <div
        :if="message"
        id="payment-message">
        {{ message }}
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const stripe = Stripe('pk_test_cjPuTDoePlvfNsPvDIUz1zlB');
const items = [{ id: 'lite' }];

export default defineComponent({
  async mounted() {
    const response = await fetch('http://localhost:8181/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items }),
    });

    const { clientSecret } = await response.json();
    const appearance = {
      theme: 'flat',
    };

    this.elements = stripe.elements({ appearance, clientSecret });

    const paymentElement = this.elements?.create('payment');
    paymentElement?.mount('#payment-element');
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: window.location.origin,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.showMessage(error.message);
      } else {
        this.showMessage('An unexpected error occured.');
      }

      this.setLoading(false);
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    showMessage(message) {
      this.message = message;
    },
  },
  data() {
    return {
      loading: false,
      message: '',
      elements: undefined,
    };
  },
});
</script>
